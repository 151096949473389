.toolbar {
	position: sticky;
	border-radius: 4px;
	z-index: 15;
	box-sizing: border-box;
	width: max-content;
	margin-top: 15px;
}

.toolbar:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #333;
	border-width: 4px;
	margin-left: -4px;
}

.toolbar:before {
	border-color: rgba(221, 221, 221, 0);
	border-top-color: #111;
	border-width: 6px;
	margin-left: -6px;
}
