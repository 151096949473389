.TextEditor--Edit {
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 10px;
  box-shadow: inset 0 1px 8px -3px #ABABAB;
  background: #fefefe; }
  .TextEditor--Edit .public-DraftEditor-content {
    min-height: 140px; }

.TextEditor--OneLine .public-DraftEditor-content {
  min-height: 0; }

.TextEditor--OneLine .public-DraftStyleDefault-block {
  overflow-x: auto;
  white-space: pre; }

.TextEditor--ReadOnly {
  padding: 10px 12px;
  border: 1px solid #bcbcbc;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
}

.studli-audiobtn {
  margin-right: 15px;
  display: inline-block;
  vertical-align: text-bottom; }
  .studli-audiobtn button {
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: transparent;
    touch-action: none;
    height: 50px;
    width: 50px;
    border: none;
    outline: none; }

.Field {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer; }
  .Field__label {
    color: #333333;
    user-select: none;
    pointer-events: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px; }

.Checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  padding: 0;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom; }
  .Checkbox__native-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: inherit; }
  .Checkbox__handle {
    display: inline-flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: transparent;
    pointer-events: none; }

.Checkbox + label {
  padding-left: 8px; }

.CheckboxIcon__box {
  color: #3c3c3c; }

.CheckboxIcon__mark {
  color: #0078d7; }

.Button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: 32px;
  padding: 0 12px;
  margin: 0;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  background-color: #eaeaea;
  color: #212121;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.02em;
  margin: inherit;
  text-decoration: none;
  text-transform: none; }
  .Button:hover, .Button:focus {
    background-color: #dddddd;
    color: #212121; }
  .Button:active {
    background-color: #d6d6d6;
    color: #212121; }
  .Button::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .Button:active {
    outline: none; }
  .Button:hover {
    cursor: pointer; }
  .Button:disabled {
    cursor: default;
    pointer-events: none; }

.Button__content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1px;
  min-height: 1px; }

.Button__icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 0;
  margin-right: 6px;
  vertical-align: top; }
  .Button__icon svg {
    width: 100%;
    height: 100%;
    margin: 0; }

.Button--standard {
  background-color: #f4f4f4;
  color: #333333;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.02em;
  margin: inherit;
  text-decoration: none;
  text-transform: none; }
  .Button--standard:hover, .Button--standard:focus {
    background-color: #e7e7e7;
    color: #333333; }
  .Button--standard:active {
    background-color: #e0e0e0;
    color: #333333; }

.Button--primary {
  background-color: #0078d7;
  color: #ffffff;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.02em;
  margin: inherit;
  text-decoration: none;
  text-transform: none; }
  .Button--primary:hover, .Button--primary:focus {
    background-color: #006abe;
    color: #ffffff; }
  .Button--primary:active {
    background-color: #0061ae;
    color: #ffffff; }

.studli-multiplechoice {
  margin: 20px 0;
  padding: 23px 10px; }
  .studli-multiplechoice.horizontal .studli-multiplechoice__alternative {
    display: inline-block; }
  .studli-multiplechoice .studli-multiplechoice__alternative {
    margin-right: 5px;
    margin-bottom: 10px; }
    .studli-multiplechoice .studli-multiplechoice__alternative .selected button.btn-multi {
      border: 2px solid blue; }
  .studli-multiplechoice button.btn-multi {
    border-radius: 5px;
    display: inline-block;
    outline: none; }
  .studli-multiplechoice.size-medium .btn-multi.text, .studli-multiplechoice.size-medium .btn-multi.sound {
    font-size: 25px;
    padding: 15px; }
  .studli-multiplechoice.size-medium .btn-multi.image {
    padding: 5px; }
    .studli-multiplechoice.size-medium .btn-multi.image img {
      width: 200px; }
  .studli-multiplechoice.size-small .btn-multi.text, .studli-multiplechoice.size-small .btn-multi.sound {
    font-size: 16px;
    padding: 5px 15px; }
  .studli-multiplechoice.size-small .btn-multi.image {
    padding: 5px; }
    .studli-multiplechoice.size-small .btn-multi.image img {
      width: 100px; }
  .studli-multiplechoice.size-large .btn-multi.text, .studli-multiplechoice.size-large .btn-multi.sound {
    font-size: 30px;
    padding: 15px; }
  .studli-multiplechoice.size-large .btn-multi.image {
    padding: 5px; }
    .studli-multiplechoice.size-large .btn-multi.image img {
      width: 300px; }
  .studli-multiplechoice .correct button.btn-multi {
    background-color: #2eb82e; }

.studli-multiplechoice.edit-mode {
  margin: 20px 0;
  padding: 23px 0px; }
  .studli-multiplechoice.edit-mode .btn-multi__editmode__media .btn-audio {
    width: 70px;
    height: 70px;
    border-radius: 0; }
  .studli-multiplechoice.edit-mode .btn-multi__editmode__media img {
    width: 150px;
    height: 75px;
    margin-right: 0; }
  .studli-multiplechoice.edit-mode .btn-multi__editmode__extra {
    vertical-align: top; }
  .studli-multiplechoice.edit-mode .btn-multi__editmode__drag,
  .studli-multiplechoice.edit-mode .btn-multi__editmode__extra,
  .studli-multiplechoice.edit-mode .btn-multi__editmode__media {
    display: inline-table; }
  .studli-multiplechoice.edit-mode .btn-multi__editmode__extra .text_and_check input[type='checkbox'] {
    width: 25px; }
  .studli-multiplechoice.edit-mode .btn-multi__editmode__extra .text_and_check .input-wrapper {
    padding-left: 5px; }
  .studli-multiplechoice.edit-mode .btn-multi__editmode__extra .mediainfo_and_edit {
    margin-top: 12px;
    font-size: 16px; }
    .studli-multiplechoice.edit-mode .btn-multi__editmode__extra .mediainfo_and_edit .btn-multi__editmode__filename {
      color: rgba(0, 0, 0, 0.5); }
  .studli-multiplechoice.edit-mode .studli-multiplechoice__alternative {
    display: block;
    margin-bottom: 10px; }
    .studli-multiplechoice.edit-mode .studli-multiplechoice__alternative input {
      font-size: 17px;
      padding: 6px;
      width: 400px;
      font-weight: 300;
      outline: none;
      border-radius: 0 7px 7px 0; }
    .studli-multiplechoice.edit-mode .studli-multiplechoice__alternative .btn-multi__editmode__remove-item-btn,
    .studli-multiplechoice.edit-mode .studli-multiplechoice__alternative .btn-multi__editmode__dragbtn {
      border: 0;
      outline: none;
    background:#fff; }
    .studli-multiplechoice.edit-mode .studli-multiplechoice__alternative .btn-multi__editmode__remove-item-btn {
      font-size: 20px;
      font-weight: 700;
      color: #333;
      cursor: pointer; }
    .studli-multiplechoice.edit-mode .studli-multiplechoice__alternative .btn-multi__editmode__dragbtn {
      cursor: move;
      vertical-align: middle;
      display:none;
  }
  .studli-multiplechoice.edit-mode .studli-multiplechoice__newitem {
    margin-top:15px; 
    display: inline-flex;
    width: 100%;  
  }
    .studli-multiplechoice.edit-mode .studli-multiplechoice__newitem input {
      font-size: 14px;
      border-radius: 7px;
      padding: 6px;
      height:40px;
      min-width: 20%;
      font-weight: 300;
      outline: none;
      margin-left: 0px;
    margin-right: 40px; }

.JSONEditor {
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 10px;
  box-shadow: inset 0 1px 8px -3px #ABABAB;
  background: #fefefe;
  overflow-x: scroll; }

.studli-blanks__editor {
  font-size: 20px; }
  .studli-blanks__editor .public-DraftEditor-content {
    min-height: 140px; }
    .studli-blanks__editor .public-DraftEditor-content div div {
      padding-bottom: 6px; }
  .studli-blanks__editor .DraftEditor-root {
    padding-bottom: 20px; }
  .studli-blanks__editor .instruct {
    font-size: 11px; }

.studli-blanks__preview {
  font-size: 20px;
  padding-top: 15px; }
  .studli-blanks__preview .wrapper h1,
  .studli-blanks__preview .wrapper h2,
  .studli-blanks__preview .wrapper h3 {
    display: inline-block; }

.studli-blanks .preview-block input {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 200;
  outline: none;
  padding: 6px;
  margin-bottom: 6px; }

.studli-blanks .preview-block.size-small input {
  font-size: 16px; }

.studli-blanks .preview-block.size-medium input {
  font-size: 26px; }

.studli-blanks .preview-block.size-large input {
  font-size: 36px; }

.studli-blanks .hidden {
  visibility: hidden; }

.block {
  position:relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
}
.block__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  color: #FFFFFF;
  padding: 8px 8px;
  background-color: #516582;
  fill: #FFFFFF;
}
.block__header--alt-color {
  background-color: #358679;
}

.block__header > .Heading {
    color: inherit;
    margin: 0 10px;
    flex:1;
    font-weight:300;
    font-size:17px;
    letter-spacing: 2px;
}
.block__content {
  padding: 8px 16px; }

.edda-editor {
  box-sizing: border-box;
  min-height: 300px;
  padding-bottom: 30px;
  position: relative;
}

.studli-audiobtn, .instruction, .title {
  display: inline-block; }

.studli-audiobtn {
  margin-right: 15px; }
  .studli-audiobtn button {
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: transparent;
    touch-action: none;
    height: 30px;
    width: 30px;
    border-radius: 5px; }

.studli-title .studli-audiobtn {
  vertical-align: text-bottom;
}

.sortable-helper {
  z-index: 10;
}

.drag-handle {
  cursor: row-resize;
  /* position: absolute;
  top:0;
  left:0; */
}