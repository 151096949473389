.buttonWrapper {
	display: inline-block;
}

.button {
	background: #333;
	color: #ddd;
	font-size: 18px;
	border: 0;
	padding-top: 5px;
	vertical-align: bottom;
	height: 34px;
	width: 36px;
	border-radius: 4px;
}

.button svg {
	fill: #ddd;
}

.button:hover,
.button:focus {
	background: #444;
	outline: 0; /* reset for :focus */
}

.active {
	color: #6a9cc9;
}

.active svg {
	fill: #6a9cc9;
}
