/**
    The sole purpose of this file is to override CSS of imported npm-packages, when a normal override using
    StyledComponents doesn't work. Leave a comment to why it did'nt work.
 */

/**
    Does'nt have a child which can be targeted with styled components. NPM package seems to have a bug
    Where is does'nt properly sets the width of a tooltip if it's created or changed after initial render?
    Didn't dare update to latest version, because a github issue said latest version when writing this
    supposedly had breaking changes.
 */
.react-tooltip-lite {
    width: auto!important;
}
